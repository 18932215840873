<template>
  <div class="order-examine-popup">
    <el-dialog title="查看轨迹"  :close-on-click-modal="false" :visible.sync="visible" width="550px" center>
      <el-form :model="dataForm" ref="dataForm" size="small" label-width="80px">
        <el-row :gutter="50">
          <el-col :span="24">
            <el-form-item label="时间" prop="dateRange" :rules="[{ required: true, message: '请选择时间', trigger: 'change'}]">
              <el-date-picker
                v-model="dataForm.dateRange"
                type="datetimerange"
                align="right"
                unlink-panels
                range-separator="-"
                value-format="yyyy-MM-dd HH:mm"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :picker-options="monthPickerOptions">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirm">确 认</el-button>
        <el-button type="primary" @click="visible = false">返 回</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data () {
    return {
      visible: false,
      dataForm: {
        dateRange: null
      },
      row: null,
      customerList: [],
      dayPickerOptions: {
        disabledDate (time) {
          return time.getTime() > Date.now()
        },
        shortcuts: [{
          text: '今天',
          onClick (picker) {
            picker.$emit('pick', new Date())
          }
        }, {
          text: '昨天',
          onClick (picker) {
            const date = new Date()
            date.setTime(date.getTime() - 3600 * 1000 * 24)
            picker.$emit('pick', date)
          }
        }, {
          text: '一周前',
          onClick (picker) {
            const date = new Date()
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', date)
          }
        }]
      },
      monthPickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      }
    }
  },
  methods: {
    init (row) {
      this.visible = true
      this.row = row
      this.dataForm.dateRange = null
    },
    confirm () {
      if (this.dataForm.dateRange && Array.isArray(this.dataForm.dateRange)) {
        let data = {
          startTime: this.dataForm.dateRange[0],
          endTime: this.dataForm.dateRange[1],
          vehiclePlate: this.row.vehiclePlate
        }
        let that = this
        this.$emit('comfirmTime', data)
        setTimeout(function () {
          that.visible = false
        }, 1000)
      } else {
        this.$message.error('请先选择时间')
      }
    }
  }
}
</script>
